<template>
  <Cond :where="where" @refresh="refresh">
    <div class="mgb10">
      <el-tag
        type="primary"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >总数: {{ tag.all }}</el-tag
      >
      <el-tag
        type="warning"
        effect="dark"
        size="small"
        style="margin-right: 10px"
        >今日注册: {{ tag.today }}</el-tag
      >
      <el-tag type="success" effect="dark" size="small"
        >昨日注册: {{ tag.yesterday }}</el-tag
      >
    </div>
    <el-table :data="tableData" style="width: 100%" border size="small" v-loading="where.loading">
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="user_name" label="用户账号" width="180">
      </el-table-column>
      <el-table-column prop="wechat" label="绑定微信" width="300">
        <template slot-scope="scope">
          <div class="f12 flex ac mgb10">
            <div v-if="scope.row.avatar_url != ''" class="bdr">
              <img :src="scope.row.avatar_url" />
            </div>
            <div v-if="scope.row.nick_name != ''">
              {{ scope.row.nick_name }}
            </div>
          </div>
          <div
            v-if="scope.row.bind_gzh == 1 || scope.row.bind_wx == 1"
            class="flex ac"
          >
            <el-tag
              size="small"
              class="mgr10"
              v-if="scope.row.bind_gzh == 1"
              type="primary"
              effect="dark"
              >[公众号]</el-tag
            ><el-tag
              size="small"
              type="success"
              effect="dark"
              v-if="scope.row.bind_wx == 1"
              >[小程序]</el-tag
            >
          </div>
          <div v-else-if="scope.row.bind_gzh == 0 && scope.row.bind_wx == 0">
            <el-tag size="small">未绑定</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="db_code" label="数据编码" width="100">
      </el-table-column>
      <el-table-column prop="table_code" label="表编码" width="100">
      </el-table-column>
      <el-table-column prop="create_time" label="注册时间" width="180">
        <template slot-scope="scope">
          {{ $func.formatPickDate(scope.row.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="is_promotion" label="代理" width="280">
        <template slot-scope="scope">
          <div
            v-if="scope.row.parent_user_name"
            style="margin-bottom: 10px; margin-top: 10px"
          >
            <el-tag class="mgb10" size="small" type="danger" effect="dark"
              >上级代理:</el-tag
            >
            <div style="color: red">{{ scope.row.parent_user_name }}</div>
          </div>
          <div v-if="scope.row.is_promotion == 0">
            <el-tag size="small" type="warning" effect="dark">普通会员</el-tag>
          </div>
          <div v-else>
            <el-tag class="mgb10" size="small" type="danger" effect="dark"
              >代理会员</el-tag
            >
            <div class="f-primary">
              [分成比例：{{ scope.row.share_proportion }}%]
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <div>
            <el-button @click="changeRel(scope.row)" type="text" size="small"
              >更换关系</el-button
            >
          </div>
          <div v-if="scope.row.is_promotion == 0">
            <el-button type="text" size="small" @click="openAgent(scope.row)"
              >开通代理</el-button
            >
          </div>
          <div v-else>
            <div>
              <el-button type="text" size="small" @click="openAgent(scope.row)"
                >设置分成</el-button
              >
            </div>
            <div>
              <el-popconfirm
                title="确定要清零费用吗？"
                @confirm="clearBuy(scope.row)"
              >
                <el-button type="text" size="small" slot="reference"
                  >费用清零</el-button
                >
              </el-popconfirm>
            </div>
            <div>
              <el-popconfirm
                title="确定要关闭吗？"
                @confirm="closeAgent(scope.row)"
              >
                <el-button type="text" size="small" slot="reference"
                  >关闭代理</el-button
                >
              </el-popconfirm>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <AgentChangeRel ref="agent" @refresh="loadList"></AgentChangeRel>
    <OpenAgent ref="openagent" @refresh="loadList"></OpenAgent>
  </Cond>
</template>
<script>
import Cond from "./membercond.vue";
import AgentChangeRel from "@/components/backend/member/agentchangerel.vue";
import OpenAgent from "@/components/backend/member/openagent.vue";
export default {
  components: {
    Cond,
    AgentChangeRel,
    OpenAgent,
  },
  data() {
    return {
      url: {
        memberlist: "/sysadminmember/memberlist",
        setsharemember: "/sysadminmember/setsharemember",
        clearsharemember: "/sysadminmember/clearsharemember",
      },
      where: {
        username: "",
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      tableData: [],
      tag: {
        today: 0,
        yesterday: 0,
        all:0,
      },
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.memberlist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          this.tag.today = rs.today_increase;
          this.tag.yesterday = rs.yesterday_increase;
          this.tag.all=rs.all;
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    openAgent: function (master) {
      this.$refs.openagent.show(master);
    },
    clearBuy: function (master) {
      this.where.loading = true;
      this.$rq
        .postAction(this.url.clearsharemember, { id: master.id })
        .then((rs) => {
          if (rs.code == 1) {
            this.loadList();
          } else {
            this.$message({
              type: "warning",
              message: rs.err,
            });
          }
        });
    },
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    changeRel: function (row) {
      this.$refs.agent.show(row);
    },
    closeAgent: function (row) {
      this.where.loading = true;
      this.$rq
        .postAction(this.url.setsharemember, {
          id: row.id,
          is_promotion: 0,
          share_proportion: 0,
        })
        .then((rs) => {
          if (rs.code == 1) {
            this.loadList();
          } else {
            this.$message({
              type: "warning",
              message: rs.err,
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.bdr {
  border: 1px solid #ececec;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  overflow: hidden;
  background-color: #ccc;
  margin-right: 8px;
}
.bdr > img {
  width: 100%;
  height: 100%;
}
</style>