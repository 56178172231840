<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
    :loading="form.loading"
  >
    <el-form :inline="true" class="demo-form-inline" size="mini">
      <el-form-item label="用户账号">
        <el-input
          clearable
          placeholder="请输入用户账号"
          v-model="where.username"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="where.is_promotion" @change="refresh">
          <el-radio-button :label="-1">全部</el-radio-button>
          <el-radio-button :label="0">非代理</el-radio-button>
          <el-radio-button :label="1">代理</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="refresh"
          type="primary"
          icon="el-icon-search"
          circle
        ></el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" style="width: 100%" border size="small">
      <el-table-column prop="id" label="ID" width="80"> </el-table-column>
      <el-table-column prop="user_name" label="用户账号"> </el-table-column>
      <el-table-column prop="is_promotion" label="代理" width="180">
        <template slot-scope="scope">
          <el-tag
            size="small"
            type="primary"
            effect="dark"
            v-if="scope.row.is_promotion == 1"
            >代理</el-tag
          >
          <el-tag size="small" type="success" effect="dark" v-else
            >非代理</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.is_promotion == 1"
            @click="hup(scope.row)"
            type="text"
            size="small"
            >挂他名下</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mgt10 text-center">
      <el-pagination
        @current-change="loadList"
        :current-page.sync="where.page"
        background
        layout="prev, pager, next"
        :page-size="where.pageSize"
        :total="where.total"
      >
      </el-pagination>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "",
      visiable: false,
      where: {
        username: "",
        own_id: 0,
        is_promotion: -1,
        page: 1,
        pageSize: 20,
        total: 0,
        loading: false,
      },
      form: this.getDefaultData(),
      url: {
        memberlist: "/sysadminmember/memberlist",
        changerelation: "/sysadminmember/changerelation",
      },
      tableData: [],
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    refresh: function () {
      this.where.page = 1;
      this.loadList();
    },
    loadList: function () {
      this.where.loading = true;
      this.$rq.postAction(this.url.memberlist, this.where).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.tableData = [];
          for (var i in rs.list) {
            this.tableData.push(rs.list[i]);
          }
          this.where.total = parseInt(rs.pageInfo.total);
        }
      });
    },
    getDefaultData: function () {
      return {
        sub_master_id: 0,
        parent_master_id: 0,
      };
    },
    handleClose: function () {
      this.$emit("refresh");
      this.visiable = false;
    },
    show: function (row) {
      this.where.is_promotion = -1;
      this.title = "更换关系";
      this.form.sub_master_id = row.id;
      this.parent_master_id = 0;
      this.where.own_id = row.id;
      this.visiable = true;
      this.loadList();
    },
    hup: function (row) {
      this.where.loading = true;
      this.form.parent_master_id = row.id;
      this.$rq.postAction(this.url.changerelation, this.form).then((rs) => {
        this.where.loading = false;
        if (rs.code == 1) {
          this.handleClose();
        } else {
          this.$message({
            type: "warning",
            message: rs.err,
          });
        }
      });
    },
    submit: function () {
      this.handleClose();
    },
  },
};
</script>
