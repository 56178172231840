<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="title"
    :visible.sync="visiable"
    :before-close="handleClose"
  >
    <el-form ref="form" :rules="rules" :model="form">
      <el-form-item label="分成比例" prop="share_proportion">
        <el-input-number
          v-model="form.share_proportion"
          label="请输入收货天数"
          :min="0"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="form.loading" @click="submit"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      title: "代理设置",
      visiable: false,
      form: this.getDefaultData(),
      rules: {
        share_proportion: [
          { required: true, message: "请输入分成比例", trigger: "blur" },
        ],
      },
      url: {
        setsharemember: "/sysadminmember/setsharemember",
      },
    };
  },
  created() {},
  methods: {
    getDefaultData: function () {
      return {
        id: 0,
        share_proportion: 0,
        is_promotion: 1,
        loading: false,
      };
    },
    handleClose: function () {
      this.$refs.form.resetFields();
      this.visiable = false;
    },
    show: function (master) {
      if (master) {
        this.form = this.$func.copy(master);
        this.form.is_promotion = 1;
      } else {
        this.form = this.getDefaultData();
      }
      this.visiable = true;
    },
    submit: function () {
      this.$refs.form.validate((v) => {
        if (v) {
          this.form.loading = true;
          this.$rq.postAction(this.url.setsharemember, this.form).then((rs) => {
            this.form.loading = false;
            if (rs.code == 1) {
              this.visiable = false;
              this.$message({
                type: "success",
                message: rs.msg,
              });
              this.$emit("refresh");
            } else {
              this.$message({
                type: "warning",
                message: rs.msg,
              });
            }
          });
        }
      });
    },
  },
};
</script>
